export const calculateMonth = (birthday) => {
  console.log(birthday)
  let ageDate = new Date(Date.now() - birthday.getTime())
  let today = new Date()
  let birthdayM = birthday.getMonth()
  if (
    Math.abs(ageDate.getUTCFullYear() - 1970) >= 18 &&
    birthdayM < today.getMonth()
  )
    return true
  return false
}

export const calculateDate = (birthday) => {
  let today = new Date()
  if (birthday.getDate() <= today.getDate()) return true
  return false
}

export const toNumbers = (arr) => {
  let string = ""
  arr.map((str) => {
    string += str
  })
  return Number(string)
}
